import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "components/logo"

const Header = ({ siteTitle }) => (
  <header className="px-8 py-4 bg-white text-red-700">
    <div className="max-w-screen-xl mx-auto flex justify-between items-center">
      <Link to="/" className="text-red-700">
        <Logo />
      </Link>
      <div className="hidden sm:block">
        <Link to="/#cursos" className="text-base text-gray-700 p-4 font-medium">
          {`Cursos`}
        </Link>
        <Link to="/extraescolars" className="text-base text-gray-700 p-4 font-medium">
          {`Extraescolars`}
        </Link>
        <Link to="/#tallers" className="text-base text-gray-700 p-4 font-medium">
          {`Tallers de Teatre`}
        </Link>
        <Link to="/#contacte" className="text-base text-gray-700 p-4 font-medium">
          {`Contacte`}
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
