import React from 'react'

const Footer = () => {
  return (
    <footer className="py-20 bg-red-700 text-red-100 ">
    <div className="max-w-screen-xl mx-auto text-center">

      <div>
        <ul className="flex justify-center items-center">
          <li>
            <a className="text-red-100 mr-8" target="_blank" href="https://www.facebook.com/GaudirTeatre-141160262692213/" rel="noreferrer noopener">
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="inline-block fill-current h-10 w-10"
              >
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-2 10H8v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667H15V6h-2.404C10.798 6 10 6.792 10 8.308V10z"></path>
              </svg>
            </a>
          </li>
          <li>
            <a className="text-red-100 mr-8" target="_blank" href="https://www.instagram.com/gaudirteatre/" rel="noreferrer noopener">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="inline-block fill-current h-8 w-8"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z"></path>
              </svg>
            </a>
          </li>
          <li>
            <a className="text-red-100" target="_blank" href="https://www.youtube.com/channel/UCOcnRfVnETgjuoFv0s_H4Jg" rel="noreferrer noopener">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="inline-block fill-current h-10 w-10"
              >
                <path d="M8.326 6h.721l.493 1.851L9.998 6h.727l-.832 2.752v1.878h-.716V8.752L8.326 6zm3.297 1.187c-.56 0-.931.37-.931.917v1.675c0 .602.314.916.931.916.511 0 .913-.342.913-.916V8.104a.891.891 0 00-.913-.917zm.266 2.563c0 .186-.095.323-.266.323-.176 0-.277-.144-.277-.323V8.161c0-.187.086-.326.265-.326.194 0 .278.134.278.326V9.75zm2.364-2.535v2.593c-.077.097-.25.256-.374.256-.135 0-.169-.093-.169-.23V7.215h-.635v2.855c0 .337.103.61.443.61.192 0 .459-.1.734-.426v.377h.635V7.215h-.634zm1.101 6.929c-.224 0-.271.158-.271.382v.329h.535v-.329c.001-.221-.046-.382-.264-.382zm-2.351.02l-.125.099v2.027l.144.115c.099.05.242.054.31-.034.034-.046.052-.121.052-.224v-1.68a.41.41 0 00-.064-.25c-.074-.096-.211-.107-.317-.053zm2.413-2.598c-1.301-.089-5.533-.088-6.833 0-1.406.097-1.572.947-1.583 3.184.011 2.233.175 3.087 1.583 3.184 1.3.088 5.532.089 6.833 0 1.407-.096 1.573-.946 1.584-3.184-.011-2.233-.175-3.087-1.584-3.184zM9.254 16.91h-.681v-3.77h-.705v-.64h2.091v.64h-.705v3.77zm2.424 0h-.605v-.359a1.33 1.33 0 01-.348.302c-.326.187-.773.183-.773-.477v-2.72h.604v2.494c0 .131.032.219.161.219.118 0 .282-.151.355-.244v-2.47h.605v3.255zm2.328-.675c0 .403-.15.716-.553.716-.222 0-.406-.081-.575-.292v.25h-.61V12.5h.61v1.42c.137-.167.322-.304.538-.304.443 0 .59.374.59.815v1.804zm2.235-.876h-1.157v.614c0 .244.021.455.265.455.255 0 .271-.172.271-.455v-.226h.622v.244c0 .627-.269 1.007-.906 1.007-.577 0-.873-.421-.873-1.007v-1.46c0-.565.373-.957.919-.957.58 0 .86.369.86.957v.828zM12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0z"></path>
              </svg>
            </a>
          </li>
        </ul>
      </div>

      <div className="font-base pt-12">Copyright © {new Date().getFullYear()} Gaudir Teatre</div>

    </div>
  </footer>
    )
}

export default Footer
